<template>
  <div>
    <span
      class="warning--text font-weight-black"
    >
      #
    </span>
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'BaseKeyword',
  }
</script>
