var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: function (isVisible, entry) { return _vm.changeAnchor(isVisible, entry); },
    intersection: {
      threshold: 0.5
    },
  }),expression:"{\n    callback: (isVisible, entry) => changeAnchor(isVisible, entry),\n    intersection: {\n      threshold: 0.5\n    },\n  }"}],staticClass:"overflow-hidden",style:({
    height: '100vh',
    backgroundImage: 'url(' + require('@/assets/backgrounds/splash.jpg') + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom',
  }),attrs:{"id":"welcome"}},[_c('v-row',{style:({ height:'100%' }),attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center layout wrap py-10",attrs:{"cols":"10"}},[_c('v-card',{staticClass:"pa-10",staticStyle:{"background-color":"rgba(255,255,255,0.8)"},attrs:{"elevation":"16","rounded":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mb-10",attrs:{"src":require('@/assets/logos/logo_entreprise.png'),"max-height":"150","contain":""}})],1),_c('v-col',{class:'d-flex align-center ' + (_vm.$vuetify.breakpoint.mobile ? 'justify-start' : 'justify-center'),attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '4'}},[_c('base-keyword',{class:'welcomeKeyword primary--text ' + (_vm.$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4'),attrs:{"id":"welcomeRealisations"}},[_c('a',{attrs:{"href":"#projects"}},[_vm._v(" Réalisations ")])])],1),_c('v-col',{class:'d-flex align-center ' + (_vm.$vuetify.breakpoint.mobile ? 'justify-start' : 'justify-center'),attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '4'}},[_c('base-keyword',{class:'welcomeKeyword primary--text ' + (_vm.$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4'),attrs:{"id":"welcomeServices"}},[_c('a',{attrs:{"href":"#services"}},[_vm._v(" Services ")])])],1),_c('v-col',{class:'d-flex align-center ' + (_vm.$vuetify.breakpoint.mobile ? 'justify-start' : 'justify-center'),attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : '4'}},[_c('base-keyword',{class:'welcomeKeyword primary--text ' + (_vm.$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4'),attrs:{"id":"welcomeAbout"}},[_c('a',{attrs:{"href":"#about"}},[_vm._v(" À propos ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }