<template>
  <section
    id="welcome"
    v-observe-visibility="{
      callback: (isVisible, entry) => changeAnchor(isVisible, entry),
      intersection: {
        threshold: 0.5
      },
    }"
    class="overflow-hidden"
    :style="{
      height: '100vh',
      backgroundImage: 'url(' + require('@/assets/backgrounds/splash.jpg') + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'right bottom',
    }"
  >
    <v-row
      justify="center"
      :style="{ height:'100%' }"
      no-gutters
    >
      <v-col
        class="d-flex justify-center align-center layout wrap py-10"
        cols="10"
      >
        <v-card
          elevation="16"
          class="pa-10"
          style="background-color: rgba(255,255,255,0.8)"
          rounded
        >
          <v-row>
            <v-col
              class="d-flex justify-center align-center"
              cols="12"
            >
              <v-img
                class="mb-10"
                :src="require('@/assets/logos/logo_entreprise.png')"
                max-height="150"
                contain
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
              :class="'d-flex align-center ' + ($vuetify.breakpoint.mobile ? 'justify-start' : 'justify-center')"
            >
              <base-keyword
                id="welcomeRealisations"
                :class="'welcomeKeyword primary--text ' + ($vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4')"
              >
                <a href="#projects">
                  Réalisations
                </a>
              </base-keyword>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
              :class="'d-flex align-center ' + ($vuetify.breakpoint.mobile ? 'justify-start' : 'justify-center')"
            >
              <base-keyword
                id="welcomeServices"
                :class="'welcomeKeyword primary--text ' + ($vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4')"
              >
                <a href="#services">
                  Services
                </a>
              </base-keyword>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
              :class="'d-flex align-center ' + ($vuetify.breakpoint.mobile ? 'justify-start' : 'justify-center')"
            >
              <base-keyword
                id="welcomeAbout"
                :class="'welcomeKeyword primary--text ' + ($vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4')"
              >
                <a href="#about">
                  À propos
                </a>
              </base-keyword>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
  import { nav } from '@/components/mixins/nav'
  import BaseKeyword from '@/components/base/Keyword'

  export default {
    components: { BaseKeyword },
    mixins: [nav],
  }
</script>
<style lang="sass">
.welcomeKeyword a
  text-decoration: none
.welcomeKeyword a:hover
  color: var(--v-info-base)
</style>
